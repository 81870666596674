import { create } from "zustand";
import { Data, Selection } from "../types";
import { createJSONStorage, persist } from "zustand/middleware";
import { parseCommands, parseDataRefs } from "../dataUtil";

interface IState {
  search: string;
  selected: Selection;
  scrollTo: string;
  favourites: Data[];
  commands: Data[];
  commandSections: string[];
  datarefs: Data[];
  datarefSections: string[];
}

export const defaultState: IState = {
  search: "",
  selected: "all",
  scrollTo: "",
  favourites: [],
  commands: [],
  commandSections: [],
  datarefs: [],
  datarefSections: [],
};

interface StateStore extends IState {
  setSelected: (selected: Selection) => void;
  setSearch: (search: string) => void;
  setScrollTo: (section: string) => void;
  updateFavourites: (data: Data) => void;
  addFavourite: (data: Data) => void;
  removeFavourite: (data: Data) => void;
  loadCmds: () => void;
  loadDrs: () => void;
  reset: () => void;
}

const useStore = create<StateStore>()(
  persist(
    (set) => ({
      ...defaultState,
      setSelected: (selected: Selection) =>
        set((state) => ({ ...state, ...{ selected } })),
      setSearch: (search: string) =>
        set((state) => ({ ...state, ...{ search } })),
      setScrollTo: (section: string) => set({ scrollTo: section }),
      updateFavourites: (data: Data) =>
        set((state) => ({
          ...state,
          favourites: [...state.favourites, data],
        })),
      addFavourite: (data: Data) =>
        set((state) => {
          // Prevent adding duplicate items
          if (state.favourites.some((fav) => fav.name === data.name)) {
            return state;
          }
          return { ...state, favourites: [...state.favourites, data] };
        }),
      removeFavourite: (data: Data) =>
        set((state) => ({
          ...state,
          favourites: state.favourites.filter((fav) => fav.name !== data.name),
        })),
      loadCmds: () => {
        const cmds = parseCommands(); // Ensure parseCommands is defined and imported
        set((state) => ({
          ...state,
          commands: cmds.data,
          commandSections: cmds.sections,
        }));
      },
      loadDrs: () => {
        const drs = parseDataRefs(); // Ensure parseDataRefs is defined and imported
        set((state) => ({
          ...state,
          datarefs: drs.data,
          datarefSections: drs.sections,
        }));
      },
      reset: () => set(() => ({ ...defaultState })),
    }),
    {
      name: "xptoolkit",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ favourites: state.favourites }),
    },
  ),
);

useStore.getState().loadCmds();
useStore.getState().loadDrs();

export default useStore;
