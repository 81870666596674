function interpolateColor(
  color1: string,
  color2: string,
  factor: number = 0.5,
): string {
  const hex1 = parseInt(color1.slice(1), 16);
  const hex2 = parseInt(color2.slice(1), 16);
  const r = Math.round((hex1 >> 16) + factor * ((hex2 >> 16) - (hex1 >> 16)))
    .toString(16)
    .padStart(2, "0");
  const g = Math.round(
    ((hex1 >> 8) & 0x00ff) +
      factor * (((hex2 >> 8) & 0x00ff) - ((hex1 >> 8) & 0x00ff)),
  )
    .toString(16)
    .padStart(2, "0");
  const b = Math.round(
    (hex1 & 0x0000ff) + factor * ((hex2 & 0x0000ff) - (hex1 & 0x0000ff)),
  )
    .toString(16)
    .padStart(2, "0");
  return `#${r}${g}${b}`;
}

function generateGradientColors(
  startColor: string,
  endColor: string,
  steps: number,
): string[] {
  const gradient: string[] = [];
  const stepFactor = 1 / (steps - 1);
  for (let i = 0; i < steps; i++) {
    gradient.push(interpolateColor(startColor, endColor, i * stepFactor));
  }
  return gradient;
}

function consoleLogger(
  text: string,
  startColor: string = "#76fa8a",
  endColor: string = "#6eb0ff",
) {
  const colors: string[] = generateGradientColors(
    startColor,
    endColor,
    text.length,
  );

  const styledText: string = text
    .split("")
    .map((char, _) => `%c${char}`)
    .join("");

  const colorStyles: string[] = colors.map(
    (color) => `color: ${color}; font-size: 32px;`,
  );

  console.log(styledText, ...colorStyles);
}

export default consoleLogger;
