import {JsonDocument} from "./types";

import commandsData from "./Commands.json";
import datarefsData from "./DataRefs.json";

function assertIsCommandData(document: any): asserts document is JsonDocument {
    if (!Array.isArray(document.data) || !Array.isArray(document.sections)) {
        throw new Error("Data is not formatted correctly");
    }

    document.data.forEach((item: any) => {
        if (item.type !== "command") {
            throw new Error("Invalid command data");
        }
    });
}

function assertIsDataRefData(document: any): asserts document is JsonDocument {
    if (!Array.isArray(document.data) || !Array.isArray(document.sections)) {
        throw new Error("Data is not formatted correctly");
    }

    document.data.forEach((item: any) => {
        if (item.type !== "dataref") {
            throw new Error("Invalid dataref data");
        }
    });
}

export function parseCommands(): JsonDocument {
    assertIsCommandData(commandsData);
    return commandsData;
}

export function parseDataRefs(): JsonDocument {
    assertIsDataRefData(datarefsData);
    return datarefsData;
}
