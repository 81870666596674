import React from "react";
import "./App.css";
import { ToastProvider } from "./ToastContext";
import DataList from "./components/DataList";
import consoleLogger from "./consoleLogger";
import Sidebar from "./components/Sidebar";
import Popup from "./components/Popup";

const logText = () => {
  consoleLogger("Welcome...", "#76fa8a", "#6eb0ff");

  setTimeout(() => {
    consoleLogger("catch me @ linkedin.com/in/redr", "#fa7676", "#cf6eff");
  });
};

const Header = () => {
  return (
    <div
      className={
        "sticky top-0 h-[73px] z-40 w-full backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 dark:border-slate-50/[0.06] bg-white/95 supports-backdrop-blur:bg-white/60 dark:bg-transparent"
      }
    >
      <div className={"max-w-8xl mx-auto"}>
        <div
          className={
            "py-4 border-b border-slate-900/10 lg:px-8 lg:border-0 dark:border-slate-300/10 mx-4 lg:mx-0"
          }
        >
          <div className={"relative flex items-center justify-between"}>
            <div
              className={"font-righteous text-4xl select-none dark:text-white"}
            >
              <Popup delay={0.2}>X-Plane Toolkit</Popup>
            </div>
            <Popup delay={0.4}>Ver: X-Plane 12.0.8</Popup>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className={"text-sm leading-6 mt-12"}>
      <div
        className={
          "pt-10 pb-28 border-t border-slate-200 sm:flex justify-between text-slate-500 dark:border-slate-200/5 "
        }
      >
        <div className={"mb-6 sm:mb-0 sm:flex"}>
          <p>© 2023 XpToolkit.</p>
        </div>
      </div>
    </div>
  );
};

const Blur = () => {
  return (
    <div
      className={
        "absolute z-20 top-0 inset-x-0 flex justify-center overflow-hidden pointer-events-none"
      }
    >
      <div className={"w-[108rem] flex-none flex justify-end"}>
        <img
          className={"w-[71.75rem] flex-none max-w-none dark:hidden"}
          src={"/images/blur1.png"}
          alt={"blur effect"}
        />
        <img
          className={"w-[90rem] flex-none max-w-none hidden dark:block"}
          src={"/images/blur2.png"}
          alt={"blur effect"}
        />
      </div>
    </div>
  );
};

function App() {
  logText();
  return (
    <ToastProvider>
      <main className="min-h-screen h-full w-full antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900 ">
        <Blur />
        <Header />
        <div
          className={"max-w-8xl mx-auto px-4 sm:px-6 md:px-8 "}
          style={{ height: "calc(100vh - 73px)" }}
        >
          <Sidebar />
          <div className={"lg:pl-[19.5rem] h-full"}>
            <DataList />
            <Footer />
          </div>
        </div>
      </main>
    </ToastProvider>
  );
}

export default App;
