import React, { useEffect, useMemo, useRef } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import Item from "./Item";
import SectionHeader from "./SectionHeader";
import useStore from "../hooks/useStore";
import { shallow } from "zustand/shallow";

const DataList = () => {
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const [selected, filter, scrollTo, datarefs, commands] = useStore(
    (store) => [
      store.selected,
      store.search,
      store.scrollTo,
      store.datarefs,
      store.commands,
    ],
    shallow,
  );

  const favourites = useStore((store) => store.favourites);

  const dataList = useMemo(() => {
    if (selected === "datarefs") return datarefs;
    if (selected === "commands") return commands;

    const all = [...datarefs, ...commands];
    if (selected === "starred")
      return all.filter((d) => favourites.some((fav) => fav.name === d.name));
    return all;
  }, [selected, favourites]);

  const filteredData = useMemo(() => {
    const searchTerms = filter.toLowerCase().split(" ");

    return dataList
      .filter((d) => {
        return searchTerms.every(
          (term) =>
            d.name.toLowerCase().includes(term) ||
            (d.description && d.description.toLowerCase().includes(term)),
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [filter, dataList, favourites]);

  useEffect(() => {
    if (scrollTo) {
      const sectionIndex = filteredData.findIndex(
        (d) => d.section === scrollTo,
      );
      if (sectionIndex !== -1) {
        virtuosoRef.current?.scrollToIndex({ index: sectionIndex });
      }
    }
  }, [scrollTo]);

  return (
    <div className={"pt-10 max-w-none xl:ml-0 h-full"}>
      <Virtuoso
        ref={virtuosoRef}
        style={{ height: "100%", width: "100%" }}
        totalCount={filteredData.length}
        itemContent={(index) => {
          const itemData = filteredData[index];
          const prevItemData = index > 0 ? filteredData[index - 1] : null;

          const shouldDisplaySection =
            !prevItemData || itemData.section !== prevItemData.section;

          return (
            <>
              {shouldDisplaySection && (
                <SectionHeader type={itemData.type} text={itemData.section} />
              )}
              <Item data={itemData} />
            </>
          );
        }}
      />
    </div>
  );
};

export default DataList;
