import Badge from "./Badge";
import { useMemo } from "react";
import Indicator from "./Indicator";
import Favourite from "./Favourite";
import { useToast } from "../ToastContext";
import { Data, DataType } from "../types";
import useStore from "../hooks/useStore";
import { shallow } from "zustand/shallow";

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};
const CopyText = ({ type, children }: { type: DataType; children: string }) => {
  const { showToast } = useToast();

  const onClick = () => {
    copyToClipboard(children);
    showToast(
      <>
        Copied <span className={"font-bold"}>{children}</span>
      </>,
    );
  };
  return (
    <div
      className={`cursor-pointer text-md  leading-6 tracking-wide font-bold  ${
        type === "dataref"
          ? "text-sky-600 dark:text-sky-300"
          : "text-indigo-600  dark:text-indigo-300"
      }`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const Item = ({ data }: { data: Data }) => {
  const badgeColor = useMemo(() => {
    switch (data.status) {
      case "replaced":
        return "amber";
      case "deprecated":
        return "red";
    }
    return "grey";
  }, [data]);

  const [indicatorText, indicatorColor] = useMemo((): [
    string,
    "blue" | "green" | "grey",
  ] => {
    if (data.type === "command") {
      return ["executable", "blue"];
    }
    if (data.writable) {
      return ["read/write", "green"];
    } else {
      return ["read-only", "grey"];
    }
  }, [data]);

  return (
    <div
      className={`border-b border-slate-200 dark:border-slate-400/20 w-full p-2`}
    >
      <div className={"flex items-center gap-2"}>
        <Favourite data={data} />

        <div className={"flex flex-col gap-1.5 w-full"}>
          <div className={"flex justify-between "}>
            <div className={"flex gap-2 items-center "}>
              <CopyText type={data.type}>{data.name}</CopyText>
              {data.status !== "current" && (
                <Badge text={data.status} color={badgeColor} type={"pill"} />
              )}
            </div>
            <div>
              {data.type === "dataref" && (
                <div className={"flex gap-4"}>
                  <div
                    className={
                      "capitalize font-mono text-slate-700  dark:text-slate-400"
                    }
                  >
                    {data.unit}
                  </div>
                  {data.dataType && (
                    <Badge text={data.dataType} color={"grey"} />
                  )}
                </div>
              )}
            </div>
          </div>

          <div className={"flex justify-between"}>
            <div
              id="description"
              className={"text-sm leading-6 text-slate-900 dark:text-slate-200"}
            >
              {data.description}
            </div>
            {data.writable !== null && (
              <Indicator text={indicatorText} color={indicatorColor} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
