import Star from "./svgs/Star";
import { Data } from "../types";
import useStore from "../hooks/useStore";
import { shallow } from "zustand/shallow";

const Favourite = ({ data }: { data: Data }) => {
  const [favourites, add, remove] = useStore(
    (store) => [store.favourites, store.addFavourite, store.removeFavourite],
    shallow,
  );

  const isFavourited = favourites.some((fav) => fav.name === data.name);

  return (
    <div
      className={`relative rounded-full h-10 aspect-square hover:bg-grey/25 transition-all duration-300 cursor-pointer`}
      onClick={() => (isFavourited ? remove(data) : add(data))}
    >
      <div className={`absolute-center`}>
        <Star selected={isFavourited} />
      </div>
    </div>
  );
};

export default Favourite;
