import { useMemo } from "react";

export type DotProps = {
  color: "grey" | "green" | "blue";
};
export type IndicatorProps = DotProps & {
  text: string;
};

const Dot = ({ color }: DotProps) => {
  const style = useMemo(() => {
    switch (color) {
      case "grey":
        return "bg-gunMetal";
      case "green":
        return "bg-pastelGreen";
      case "blue":
        return "bg-pastelBlue";
    }
  }, [color]);

  return (
    <div className={`relative rounded-full w-4 h-4 ${style} bg-opacity-25`}>
      <div className={`absolute-center rounded-full w-1.5 h-1.5 ${style}`} />
    </div>
  );
};
const Indicator = ({ text, color }: IndicatorProps) => {
  return (
    <div className={"flex gap-1.5 items-center text-sm"}>
      <Dot color={color} />
      <div
        className={
          "capitalize text-w font-light whitespace-nowrap text-slate-900 dark:text-slate-200"
        }
      >
        {text}
      </div>
    </div>
  );
};
export default Indicator;
