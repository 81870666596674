import { ReactNode, useMemo } from "react";
import useStore from "../hooks/useStore";
import { shallow } from "zustand/shallow";

const HeadingText = ({
  isSelected,
  setSelected,
  text,
  svg,
}: {
  isSelected: boolean;
  setSelected: () => void;
  text: string;
  svg: ReactNode;
}) => {
  return (
    <li className={"cursor-pointer"} onClick={setSelected}>
      <a
        className={`${
          isSelected
            ? "font-semibold text-sky-500 dark:text-sky-400"
            : "font-medium text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300"
        } group flex items-center lg:text-sm lg:leading-6 mb-4`}
      >
        <div
          className={`${
            isSelected
              ? "dark:bg-sky-500"
              : "dark:group-hover:bg-indigo-500 dark:bg-slate-800"
          } mr-4 rounded-md ring-1 ring-slate-900/5 shadow-sm group-hover:shadow group-hover:ring-slate-900/10 dark:ring-0 dark:shadow-none dark:group-hover:shadow-none dark:group-hover:highlight-white/10 group-hover:shadow-indigo-200  dark:highlight-white/5`}
        >
          {svg}
        </div>
        {text}
      </a>
    </li>
  );
};

const HeadingSelection = () => {
  const [selection, setSelected] = useStore(
    (state) => [state.selected, state.setSelected],
    shallow,
  );

  return (
    <>
      <HeadingText
        text={"All"}
        isSelected={selection === "all"}
        setSelected={() => setSelected("all")}
        svg={
          <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none">
            <path
              d="m6 9 6-3 6 3v6l-6 3-6-3V9Z"
              className="fill-indigo-100 group-hover:fill-indigo-200 dark:fill-slate-400"
            ></path>
            <path
              d="m6 9 6 3v7l-6-3V9Z"
              className="fill-indigo-300 group-hover:fill-indigo-400 dark:group-hover:fill-indigo-300 dark:fill-slate-500"
            ></path>
            <path
              d="m18 9-6 3v7l6-3V9Z"
              className="fill-indigo-400 group-hover:fill-indigo-500 dark:group-hover:fill-indigo-400 dark:fill-slate-600"
            ></path>
          </svg>
        }
      />
      <HeadingText
        text={"Datarefs"}
        isSelected={selection === "datarefs"}
        setSelected={() => setSelected("datarefs")}
        svg={
          <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 12a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
              className="fill-pink-400 group-hover:fill-pink-500 dark:group-hover:fill-pink-300 dark:fill-slate-600"
            ></path>
            <path
              d="M11.082 9.107a.685.685 0 0 0-.72-.01.757.757 0 0 0-.362.653v4.5c0 .27.138.52.362.653.224.133.5.13.72-.01l3.571-2.25A.758.758 0 0 0 15 12a.758.758 0 0 0-.347-.643l-3.571-2.25Z"
              className="fill-pink-50 group-hover:fill-pink-100 dark:group-hover:fill-white dark:fill-slate-400"
            ></path>
          </svg>
        }
      />
      <HeadingText
        text={"Commands"}
        isSelected={selection === "commands"}
        setSelected={() => setSelected("commands")}
        svg={
          <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none">
            <path
              d="M4 12a7 7 0 0 1 7-7h2a7 7 0 1 1 0 14h-2a7 7 0 0 1-7-7Z"
              className="fill-blue-400 group-hover:fill-blue-500 dark:group-hover:fill-blue-400 dark:fill-slate-600"
            ></path>
            <path
              d="M10.25 9.75 7.75 12l2.5 2.25"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-blue-50 dark:stroke-slate-400 dark:group-hover:stroke-white"
            ></path>
            <path
              d="m13.75 9.75 2.5 2.25-2.5 2.25"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-blue-200 dark:group-hover:stroke-white dark:stroke-slate-400"
            ></path>
          </svg>
        }
      />
      <HeadingText
        text={"Starred"}
        isSelected={selection === "starred"}
        setSelected={() => setSelected("starred")}
        svg={
          <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none">
            <path
              d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
              className="fill-purple-400 group-hover:fill-purple-500 dark:group-hover:fill-purple-300 dark:fill-slate-600"
            ></path>
            <path
              d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
              className="fill-purple-200 group-hover:fill-purple-300 dark:group-hover:fill-white dark:fill-slate-400"
            ></path>
            <path
              d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
              className="fill-purple-400 group-hover:fill-purple-500 dark:group-hover:fill-purple-300 dark:fill-slate-600"
            ></path>
          </svg>
        }
      />
    </>
  );
};

const SectionChoice = ({
  name,
  children,
}: {
  name: string;
  children: ReactNode;
}) => {
  const setScrollTo = useStore((store) => store.setScrollTo);
  return (
    <a
      className={
        "cursor-pointer block border-l pl-4 -ml-px border-transparent hover:border-slate-400 dark:hover:border-slate-500 text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300"
      }
      onClick={() => setScrollTo(name)}
    >
      {children}
    </a>
  );
};

const escapeRegExp = (string: string) => {
  // Escape special characters for regular expression
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const SectionSelection = ({ name, data }: { name: string; data: string[] }) => {
  const [search, favourites, selected, datarefs, commands] = useStore(
    (store) => [
      store.search,
      store.favourites,
      store.selected,
      store.datarefs,
      store.commands,
    ],
    shallow,
  );

  const highlightText = (text: string, query: string) => {
    if (!query) return text;
    const escapedQuery = escapeRegExp(query); // Escape special characters
    const parts = text.split(new RegExp(`(${escapedQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className={"bg-yellow-200"}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  };

  const matchesSearchTerm = (sectionName: string) => {
    const searchTerms = search.toLowerCase().split(" ");
    return datarefs
      .concat(commands)
      .some(
        (item) =>
          item.section === sectionName &&
          searchTerms.every(
            (term) =>
              item.name.toLowerCase().includes(term) ||
              (item.description &&
                item.description.toLowerCase().includes(term)),
          ),
      );
  };

  const filteredData = useMemo(() => {
    let filteredSections = data;

    // Filter for starred if needed
    if (selected === "starred") {
      filteredSections = filteredSections.filter((name) =>
        favourites.some((fav) => fav.section === name),
      );
    }

    // Apply search filter
    if (search !== "") {
      filteredSections = filteredSections.filter((name) =>
        matchesSearchTerm(name),
      );
    }

    return filteredSections;
  }, [selected, search, data, favourites, datarefs, commands]);

  return (
    <li className={"mt-12 lg:mt-8"}>
      <h5
        className={
          "mb-8 lg:mb-3 font-semibold text-slate-900 dark:text-slate-200"
        }
      >
        {name}
      </h5>
      <ul
        className={
          "space-y-6 lg:space-y-2 border-l border-slate-100 dark:border-slate-800"
        }
      >
        {filteredData.map((name, index) => (
          <li key={index}>
            <SectionChoice name={name}>
              {highlightText(name, search)}
            </SectionChoice>
          </li>
        ))}
      </ul>
    </li>
  );
};

const SearchBar = () => {
  const setSearch = useStore((store) => store.setSearch);
  return (
    <div className={"sticky top-0 -ml-0.5 pointer-events-none"}>
      <div className={"h-10 bg-white dark:bg-slate-900"} />
      <div
        className={"bg-white dark:bg-slate-900 relative pointer-events-auto"}
      >
        <input
          onChange={(e) => setSearch(e.target.value)}
          className={
            " w-full text-sm leading-6 text-slate-400 rounded-md ring-1 ring-slate-900/10 shadow-sm py-1.5 pl-2 pr-3 hover:ring-slate-300 dark:bg-slate-800 dark:highlight-white/5 dark:hover:bg-slate-700 outline-none"
          }
          placeholder={"Quick search..."}
        />
      </div>
      <div className={"h-8 bg-gradient-to-b from-white dark:from-slate-900"} />
    </div>
  );
};

const Sidebar = () => {
  const [selection, datarefSections, commandSections] = useStore(
    (store) => [store.selected, store.datarefSections, store.commandSections],
    shallow,
  );
  return (
    <div
      className={
        "hidden lg:block fixed z-20 inset-0 top-[3.8125rem] left-[max(0px,calc(50%-45rem))] right-auto w-[19rem] pb-10 pl-8 pr-6 overflow-y-auto"
      }
    >
      <nav id={"nav"} className={"lg:text-sm lg:leading-6 relative"}>
        <SearchBar />
        <ul>
          <HeadingSelection />
          {selection !== "commands" && (
            <SectionSelection name={"Datarefs"} data={datarefSections} />
          )}
          {selection !== "datarefs" && (
            <SectionSelection name={"Commands"} data={commandSections} />
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
