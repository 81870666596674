import { motion } from "framer-motion";
import { ReactNode } from "react";

const Popup = ({
  delay = 0,
  duration = 0.5,
  children,
}: {
  delay?: number;
  duration?: number;
  children: ReactNode;
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, translateY: 50 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ delay: delay, duration: duration, ease: "easeOut" }}
    >
      {children}
    </motion.div>
  );
};

export default Popup;
