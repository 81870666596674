import { useMemo } from "react";

export type BadgeProps = {
  text: string;
  color: "red" | "amber" | "grey";
  type?: "badge" | "pill";
};

const Badge = ({ text, color, type = "badge" }: BadgeProps) => {
  const colorStyle = useMemo(() => {
    switch (color) {
      case "red":
        return "bg-red border-red text-red";
      case "amber":
        return "bg-amber border-amber text-amber";
      case "grey":
        return "bg-slate-500 border-slate-900 dark:border-slate-200 text-slate-900 dark:text-slate-200";
    }
  }, [color]);
  const borderStyle = useMemo(() => {
    switch (type) {
      case "badge":
        return "rounded-lg";
      case "pill":
        return "rounded-xl";
    }
  }, [type]);

  return (
    <div
      className={`px-2 ${colorStyle} ${borderStyle}  border border-opacity-50 bg-opacity-25 capitalize text-sm whitespace-nowrap font-mono font-bold`}
    >
      {text}
    </div>
  );
};

export default Badge;
