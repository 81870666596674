import React from "react";
import { DataType } from "../types";

export type SectionHeaderProps = {
  type: DataType;
  text: string;
};

const SectionHeader = ({ text, type }: SectionHeaderProps) => {
  return (
    <div
      className={`text-center text-2xl sm:text-3xl font-extrabold  tracking-tight my-8 ${
        type === "dataref"
          ? "text-sky-500 dark:text-sky-400"
          : "text-indigo-500 dark:text-indigo-400"
      }`}
    >
      {text}
    </div>
  );
};

export default SectionHeader;
